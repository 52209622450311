import ApiRequest from '../utilities/ApiRequest';
import {
    COUNTRIES,
    USER_REGISTER,
    USER_CODE_EMAIL_SEND,
    USER_CODE_PHONE_SEND,
} from '../config/api';
import {ERROR_ARR} from "../config/error";

class User {

    /**
     * 获取国际电话区号列表
     *
     * @param that
     * @param then
     */
    static countries(that, then) {
        ApiRequest.get(COUNTRIES)
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }

                // 回调函数
                then(data);
            })
            .catch(() => {

            });
    }

    /**
     * 获取国际电话区号列表
     *
     * @param that
     * @param then
     */
    static getPdf(that, then) {
        ApiRequest.get('api/agreement')
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }

                // 回调函数
                then(data);
            })
            .catch(() => {

            });
    }

    /**
     * 发送手机验证码
     *
     * @param that
     */
    static sendPhoneCode(that, then) {

        ApiRequest.post(USER_CODE_PHONE_SEND, that.form)
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }
                then();
                // 成功
                that.$Message.success(that.$i18n.t('language.successMsg4'));
            })
            .catch(() => that.loadingCode = false);
    }

    /**
     * 发送邮箱验证码
     *
     * @param that
     */
    static sendEmailCode(that, then) {

        ApiRequest.post(USER_CODE_EMAIL_SEND, that.form)
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }
                then();
                // 成功
                that.$Message.success(that.$i18n.t('language.successMsg4'));
            })
            .catch(() => that.loadingCode = false);
    }

    /**
     * 注册
     *
     * @param that
     * @param then
     */
    static register(that, then) {

        that.loading = true;

        ApiRequest.post(USER_REGISTER, that.form)
            .then(data => {
                that.loading = false;

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }

                // 回调函数
                then();
            })
            .catch(() => that.loading = false);
    }
}

export default User;
